// Import React
import React from 'react';

// Import CSS
import '../styles/AboutUs.css'

function AboutUs() {
    return (
        <section id="about-us" className="content-about-us mid-section">
            <div className="text">
                <h2 className="title-section">
                    <span className="small">Quem somos</span>
                    <span>Um pouco sobre a nossa história</span>
                </h2>
                <h4>
                    <span>
                        Nossa jornada começou com dois amigos desenvolvedores, frustrados com a qualidade
                        dos softwares, sites e processos disponíveis no mercado. Vimos a necessidade de algo
                        diferente: soluções que realmente funcionassem e atendessem às necessidades dos clientes
                        de maneira eficiente e intuitiva. Assim, decidimos unir nossas forças e experiências
                        para criar a FFHouse para se destacar pela sua excelência e inovação.
                    </span>

                    <span>
                        Desde o início, nossa missão tem sido simples: transformar ideias em produtos digitais robustos,
                        que não só funcionam bem, mas também oferecem uma experiência excepcional. Sabemos que cada detalhe
                        importa, e é por isso que nos dedicamos a criar soluções digitais feitas sob medida para o seu sucesso.
                    </span>

                    <span>
                        Se você procura por uma equipe comprometida em entregar resultados que superam expectativas, você
                        está no lugar certo. Aqui, acreditamos que cada projeto é uma oportunidade de fazer algo incrível,
                        e estamos prontos para ajudar você e o seu negócio a alcançar seus objetivos.
                    </span>
                </h4>
            </div>
            <img className="about-us" alt="about-us"/>
        </section>
    );
}

export default AboutUs;