// Import React
import React, { useState } from 'react';

// Import CSS
import '../styles/Menu.css'

// Import Icons
import { FaBars, FaTimes } from "react-icons/fa";

function Menu() {
    const [click, setClick] = useState(false);
    const handleMenuClick = () => setClick(!click);

    const handleMenuOptionClick = () => {
        if (window.innerWidth < 1050) {
            setClick(false);
        }
    };

    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    return (
        <section id="menu" className="menu">
            <div className="content">
                <img src="https://ffhouse.com.br/db/images/logo.png" alt="logo" id="logo" />

                <div className="menu-icons" onClick={handleMenuClick}>
                    {click ? <FaTimes /> : <FaBars />}
                </div>

                <div className={`sub-content ${click ? 'active' : ''}`} id="menu-global">
                    <nav className="options">
                        <ul>
                            <li> <a onClick={handleMenuOptionClick} href="#theoffice">Inicio</a> </li>
                            <li> <a onClick={handleMenuOptionClick} href="#about-us">Quem somos</a> </li>
                            <li> <a onClick={handleMenuOptionClick} href="#our-solutions">Nossas soluções</a> </li>
                            <li> <a onClick={handleMenuOptionClick} href="#cases">Cases</a> </li>
                            <li> <a onClick={handleMenuOptionClick} href="#contact">Contato</a> </li>
                        </ul>
                    </nav>

                    <button
                        className="contact-us"
                        onClick={() => handleRedirectTo('https://wa.me/5518997505150?text=Ol%C3%A1!%20Acessei%20o%20site%20de%20voc%C3%AAs%20e%20gostaria%20de%20iniciar%20o%20meu%20atendimento.')}>
                            Fale <span style={{ fontWeight: '500' }}>conosco</span>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Menu;