// Import React
import React from 'react';

// Import CSS
import '../styles/Contact.css'

const handleRedirectTo = (url) => {
    window.open(url, "_blank");
};

const IconWhatsapp = ({ onClick }) => (
    <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.56254 22.9499C6.56254 13.9718 13.698 6.69365 22.5 6.69365C31.302 6.69365 38.4375 13.9718 38.4375 22.9499C38.4375 31.9279 31.302 39.2062 22.5 39.2062C19.3646 39.2062 16.4461 38.2847 13.9837 36.6933C13.5254 36.3973 12.9639 36.3181 12.4441 36.4762L7.04567 38.1197L9.07825 33.2684C9.31849 32.695 9.26961 32.0383 8.94726 31.5085C7.43569 29.024 6.56254 26.0939 6.56254 22.9499ZM22.5 2.86865C11.6269 2.86865 2.81254 11.8593 2.81254 22.9499C2.81254 26.4653 3.69968 29.7743 5.25872 32.6514L2.02588 40.367C1.74009 41.0492 1.86677 41.8383 2.35092 42.3918C2.83506 42.9455 3.58935 43.1635 4.28623 42.9512L12.7226 40.3833C15.6046 42.0682 18.9444 43.0312 22.5 43.0312C33.3731 43.0312 42.1875 34.0405 42.1875 22.9499C42.1875 11.8593 33.3731 2.86865 22.5 2.86865ZM26.7984 27.1237L24.3343 28.8941C23.1803 28.2236 21.9043 27.2876 20.6244 25.9822C19.2939 24.6251 18.3075 23.224 17.5802 21.9388L19.1462 20.583C19.8182 20.0012 20.0021 19.0203 19.5881 18.2266L17.5928 14.4016C17.3241 13.8865 16.8397 13.5247 16.2772 13.419C15.7146 13.3134 15.1356 13.4754 14.7046 13.859L14.1131 14.3856C12.6904 15.6521 11.8491 17.7332 12.5464 19.8404C13.2693 22.0248 14.8122 25.4629 17.9729 28.6868C21.3733 32.1553 24.8274 33.5212 26.8474 34.0518C28.4749 34.479 30.015 33.9062 31.1003 33.0043L32.2093 32.0825C32.6837 31.6883 32.9396 31.0826 32.895 30.4601C32.8502 29.8376 32.5104 29.2765 31.9847 28.9569L28.8379 27.0444C28.2023 26.6582 27.4035 26.6892 26.7984 27.1237Z" fill="#0FAC3F" />
    </svg>
);

const IconInstagram = ({ onClick }) => (
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <g clipPath="url(#clip0_149_207)">
            <path d="M9.69918 0.585785C7.18719 0.99398 4.26701 2.53257 2.94822 4.13396C1.25263 6.20635 0.624632 7.96474 0.247835 11.7641C-0.254563 16.6311 0.0594356 30.3214 0.718832 32.488C1.81783 36.0362 4.42401 38.611 8.09779 39.7413C9.60498 40.2123 11.2692 40.2751 20.2181 40.2751C29.6381 40.2751 30.7371 40.2123 32.4326 39.6786C35.7924 38.5796 38.43 35.9106 39.4348 32.645C39.9372 31.075 40 29.4736 40 20.0223C40 9.44053 39.9686 9.15793 39.2778 7.39954C37.7706 3.63157 34.505 1.18238 30.2033 0.554382C27.5971 0.177586 12.0856 0.208984 9.69918 0.585785ZM30.7057 4.35376C32.7152 4.76196 34.6306 6.17495 35.4784 7.80774C36.3262 9.50333 36.6716 13.2085 36.6716 20.3363C36.6716 30.5412 36.1064 33.2102 33.5316 35.0942C31.4593 36.57 30.0149 36.727 19.1191 36.6014C9.63638 36.5072 9.16538 36.4758 7.94079 35.8164C7.24999 35.4396 6.2138 34.6546 5.7114 34.0266C3.95301 32.0798 3.89021 31.4832 3.89021 20.3363C3.89021 8.90673 3.98441 8.24734 6.0568 6.23775C8.12919 4.22816 9.35378 4.03976 19.8099 4.00836C25.3049 4.00836 29.7951 4.13396 30.7057 4.35376Z" fill="url(#paint0_linear_149_207)" />
            <path d="M29.1841 8.08765C27.6216 9.6189 28.5591 11.9939 30.7466 11.9939C32.9029 11.9939 33.8716 9.6189 32.3091 8.08765C31.9029 7.65015 31.1841 7.3064 30.7466 7.3064C30.3091 7.3064 29.5904 7.65015 29.1841 8.08765Z" fill="url(#paint1_linear_149_207)" />
            <path d="M17.6842 10.4314C8.37167 12.7751 6.93417 25.3376 15.5279 29.5563C17.3717 30.4626 17.9029 30.5876 20.1217 30.5563C23.2154 30.5563 25.4967 29.5876 27.4654 27.4313C29.4967 25.2438 30.1217 23.5876 30.1217 20.4313C30.1217 17.2751 29.4967 15.6189 27.4654 13.4314C25.1217 10.9001 20.9654 9.61885 17.6842 10.4314ZM23.6529 14.9001C27.5904 17.4939 27.5904 23.3688 23.6529 25.9626C21.9342 27.1188 19.0279 27.3376 17.2779 26.4313C12.1217 23.7751 12.1529 17.1814 17.3092 14.3689C18.8092 13.5564 22.0592 13.8064 23.6529 14.9001Z" fill="url(#paint2_linear_149_207)" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_149_207" x1="20" y1="40.2751" x2="20" y2="0.286995" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C6258B" />
                <stop offset="1" stopColor="#FEC053" />
            </linearGradient>
            <linearGradient id="paint1_linear_149_207" x1="30.743" y1="11.9939" x2="30.743" y2="7.3064" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C6258B" />
                <stop offset="1" stopColor="#FEC053" />
            </linearGradient>
            <linearGradient id="paint2_linear_149_207" x1="19.9728" y1="30.5605" x2="19.9728" y2="10.1883" gradientUnits="userSpaceOnUse">
                <stop stopColor="#C6258B" />
                <stop offset="1" stopColor="#FEC053" />
            </linearGradient>
            <clipPath id="clip0_149_207">
                <rect width="40" height="40" fill="white" transform="translate(0 0.284668)" />
            </clipPath>
        </defs>
    </svg>
);

const IconLinkedin = ({ onClick }) => (
    <svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M7.41092 0.93672C5.21952 2.37109 4.58202 5.00078 5.77733 7.35156C6.65389 9.06484 7.88905 9.74219 10.0805 9.74219C11.7539 9.74219 12.232 9.54297 13.3875 8.3875C14.5031 7.27188 14.7422 6.71406 14.7422 5.39922C14.7422 4.48281 14.4633 3.20781 14.1445 2.57031C12.9492 0.259377 9.64217 -0.497655 7.41092 0.93672Z" fill="#0077B7" />
        <path d="M32.5523 13.5273C31.3969 13.9258 30.0023 14.8023 29.0859 15.7188L27.4922 17.3125V15.5195V13.7266H23.5078H19.5234V27.2734V40.8203H23.707H27.8906V33.5289C27.9305 29.5445 28.0898 25.4805 28.2492 24.4844C29.2055 19.5836 35.7398 18.9461 37.0547 23.6078C37.2539 24.4047 37.4531 28.6281 37.4531 32.9313V40.8203H41.6367H45.8203V31.5367C45.8203 21.3367 45.4219 18.6273 43.5891 16.2367C41.5172 13.4875 36.4969 12.2922 32.5523 13.5273Z" fill="#0077B7" />
        <path d="M5.57812 27.2734V40.8203H9.96094H14.3438V27.2734V13.7266H9.96094H5.57812V27.2734Z" fill="#0077B7" />
    </svg>

);

const IconEmail = ({ onClick }) => (
    <svg width="42" height="31" viewBox="0 0 42 31" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path d="M6 0.186094C3.84179 0.617487 1.85373 2.07238 0.859701 3.94174C0.608955 4.42389 0.313433 5.12595 0.197015 5.49813L0 6.17483V15.3525V24.5301L0.20597 25.2068C0.886567 27.4822 2.53433 29.2839 4.72836 30.1382C6.12537 30.688 5.31045 30.6627 21 30.6627C36.6896 30.6627 35.8746 30.688 37.2716 30.1382C38.1493 29.7999 38.9194 29.3177 39.6537 28.641C40.6746 27.719 41.3821 26.5771 41.794 25.2068L42 24.5301V15.3525V6.17483L41.794 5.49813C41.1134 3.2143 39.4746 1.42952 37.2716 0.566734C35.8746 0.016922 36.6985 0.0422974 20.9373 0.0507565C8.58806 0.0592155 6.53731 0.0761318 6 0.186094ZM35.0149 3.68798C35.5433 3.77257 36.5015 4.16167 36.5284 4.297C36.5284 4.33084 33.7343 6.41167 30.3134 8.93235C23.6866 13.7961 23.4448 13.9568 22.1463 14.2021C21.2776 14.3713 20.7224 14.3713 19.8537 14.2021C18.5552 13.9568 18.3134 13.7961 11.7045 8.94081C8.28358 6.42859 5.48955 4.34776 5.48955 4.30546C5.4806 4.18704 6.32239 3.82332 6.85075 3.71336C7.51343 3.56956 34.1821 3.55264 35.0149 3.68798ZM9.53731 11.9352C15.806 16.5367 15.8687 16.579 16.8896 17.0358C19.9791 18.423 23.5881 18.1692 26.3731 16.3675C26.6418 16.1899 29.409 14.1767 32.5075 11.9013L38.1493 7.74814L38.1761 15.7585C38.1851 21.0198 38.1672 23.9126 38.1045 24.1918C37.9522 24.8769 37.603 25.4775 37.0567 26.0019C36.4746 26.5433 35.803 26.8816 35.0239 27.017C34.2806 27.1439 7.72836 27.1439 6.99403 27.017C6.70746 26.9662 6.24179 26.8309 5.96418 26.704C5.38209 26.4502 4.5403 25.6974 4.26269 25.1814C3.79701 24.3187 3.80597 24.4625 3.80597 15.7923C3.80597 9.34683 3.83284 7.73968 3.92239 7.79889C3.97612 7.84964 6.51045 9.71055 9.53731 11.9352Z" fill="#F04C40" />
    </svg>
);

const Socials = [
    {
        icon: <IconWhatsapp />,
        title: 'Whatsapp',
        data: '(18) 99750-5150',
        link: 'https://wa.me/5518997505150?text=Ol%C3%A1!%20Acessei%20o%20site%20de%20voc%C3%AAs%20e%20gostaria%20de%20iniciar%20o%20meu%20atendimento.'
    },
    {
        icon: <IconInstagram />,
        title: 'Instagram',
        data: 'ffhousebrasil',
        link: 'https://www.instagram.com/ffhousebrasil/'
    },
    {
        icon: <IconLinkedin />,
        title: 'Linkedin',
        data: 'ffhouse',
        link: 'https://www.linkedin.com/company/ffhouse/'
    },
    {
        icon: <IconEmail />,
        title: 'Email',
        data: 'contato@ffhouse.com.br',
        link: 'mailto:contato@ffhouse.com.br'
    }
]

function Contact() {
    const sendWhatsapp = () => {
        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const phone = document.getElementById('phone').value;
        const topic = document.getElementById('topic').value;

        const message = `Olá! Acessei o site da FFHouse e gostaria de alavancar o meu negócio falando sobre ${topic}.
        \nNome: ${name}
        \nEmail: ${email}
        \nTelefone: ${phone}`;

        const whatsappLink = `https://wa.me/5518997505150?text=${encodeURIComponent(message)}`;
        window.open(whatsappLink, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.target;

        if (form.checkValidity()) {
            sendWhatsapp();
        } else {
            form.reportValidity();
        }
    }

    return (
        <section id="contact" className="mid-section">
            <h2 className="title-section">
                <span>Contato &</span>
                <span>Redes Sociais</span>
            </h2>

            <div className="content-section">
                <nav>
                    {Socials.map((social, index) => (
                        <div className="social" key={index} onClick={() => handleRedirectTo(social.link)}>
                            {social.icon}
                            <div>
                                <h6>{social.title}</h6>
                                <h1>{social.data}</h1>
                            </div>
                        </div>
                    ))}
                </nav>

                <form id="contact-us" action="" onSubmit={handleSubmit}>
                    <title>
                        <span>Solicite o contato do</span>
                        <span>nosso time de especialistas</span>
                    </title>

                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input type="text" id="name" name="name" placeholder="Seu nome ou como prefere ser chamado" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" placeholder="Seu e-mail de contato" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telefone</label>
                        <input type="tel" id="phone" name="phone" placeholder="Dê preferência ao seu número de whatsapp" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="topic">Como podemos lhe ajudar no seu negócio?</label>
                        <select id="topic" name="item" required>
                            <option value="" hidden>Selecione uma das opções</option>
                            <option value="Desenvolvimento de Sistemas">Desenvolvimento de Sistemas</option>
                            <option value="Automação de Processos">Automação de Processos</option>
                            <option value="Social Media">Social Media</option>
                            <option value="Google">Google</option>
                            <option value="Edição de Vídeos">Edição de Vídeos</option>
                            <option value="Qualidade de Software">Qualidade de Software</option>
                            <option value="Outras opções">Outras opções</option>
                        </select>
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="send-contact whatsapp" >Enviar solicitação <span style={{ fontWeight: '500' }}>por Whatsapp</span></button>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contact;