// Import React
import React from 'react';

// Import CSS
import '../styles/WhyUs.css'

function WhyUs() {
    const IconTogether = () => {
        return (
            <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.00001 0.239564C6.1174 0.389265 4.74783 0.808426 4.21522 1.06292C3.91088 1.22759 3.16522 1.79645 2.58696
                    2.35034C1.75001 3.11382 1.36957 3.6228 0.882615 4.58088C0.289136 5.76352 0.243484 5.94316 0.182615 7.41022C0.106528
                    9.26651 0.289136 10.3743 0.973919 11.9462C2.03914 14.4162 4.04783 16.9462 7.12175 19.6857C9.67827 21.9611 13.5131
                    24.8503 13.9848 24.8503C14.2131 24.8503 15.1565 24.2515 16.5717 23.1887C21.9739 19.1617 25.0935 15.8384 26.7826
                    12.3503C27.65 10.5689 27.8631 9.6707 27.8631 7.84435C27.8478 6.28747 27.4978 4.95513 26.8131 3.89226C26.2348 2.99405
                    25.0326 1.79645 24.1957 1.2725C22.7044 0.344354 20.3304 -0.0448672 18.4739 0.344354C17.3174 0.583875 15.2631 1.52699
                    14.5022 2.15573L14.0304 2.54495L13.3913 2.021C12.4935 1.2725 11.6413 0.823396 10.6522 0.538965C9.80001 0.284474
                    7.68479 0.119803 7.00001 0.239564ZM10.5304 2.39525C10.987 2.55992 11.6261 2.9192 11.9609 3.20364L12.5391 3.69765L11.1087
                    5.25453C8.82609 7.72459 7.48696 9.32639 7.38044 9.77549C7.28914 10.1348 7.41088 10.3144 8.53696 11.4521C10.3326 13.2785
                    11.4131 13.7126 13.0109 13.2485C13.7261 13.0539 14.0913 12.7994 15.1717 11.7964C16.6935 10.4042 17.3935 10.03 18.5652
                    10.03C19.8131 10.03 20.4826 10.4192 22.2935 12.1857L23.9217 13.7725L23.6022 14.1917C23.4348 14.4312 22.9783 14.9701
                    22.5978 15.3893L21.8826 16.1677L20.4217 14.8204C18.8848 13.4132 18.4131 13.2036 17.8957 13.7126C17.3478 14.2366 17.5761
                    14.6707 19.1283 16.1078L20.6196 17.4851L19.6457 18.3533C19.1131 18.8473 18.55 19.2964 18.3978 19.3713C18.1696 19.4761
                    17.8348 19.2366 16.6174 18.1288C15.4304 17.0509 15.0196 16.7665 14.6544 16.7665C14.0913 16.7665 13.8478 17.006 13.8478
                    17.5899C13.8326 17.9641 14.0609 18.2485 15.3087 19.4012L16.7848 20.7485L15.4761 21.7515C14.7609 22.3054 14.0913 22.7545
                    14 22.7545C13.7109 22.7545 9.92175 19.7456 8.12609 18.0988C5.95001 16.0929 4.65653 14.6258 3.6674 13.0839C1.3087 9.41621
                    1.36957 5.92819 3.81957 3.65274C4.7174 2.82938 5.67609 2.30543 6.71088 2.09585C7.70001 1.90124 9.57175 2.03597 10.5304
                    2.39525ZM21.837 2.27549C23.2065 2.6198 24.8044 3.90723 25.4739 5.22459C25.9913 6.21262 26.2044 7.75453 26.0065
                    8.96711C25.8696 9.86531 25.2152 11.7815 24.987 12.006C24.9261 12.0659 24.1652 11.4521 23.2978 10.6288C21.2131 8.63777
                    20.5131 8.30843 18.5652 8.30843C17.287 8.30843 17.0131 8.35334 16.2522 8.72759C15.7804 8.95214 14.8217 9.6707 14.1065
                    10.3144C12.7522 11.5569 12.2196 11.7964 11.4739 11.527C11.0174 11.3473 9.58696 10.0749 9.58696 9.83537C9.58696 9.65573
                    11.3217 7.63477 12.7674 6.10783C14.7152 4.05693 17.1804 2.44016 18.8848 2.09585C19.6152 1.96112 20.8783 2.021 21.837
                    2.27549Z" fill="#F04C40" />
            </svg>
        )
    }

    const IconRocket = () => {
        return (
            <svg width="28" height="25" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <svg width="28" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_195_13)">
                        <path d="M21.0191 0.159197C19.2994 0.382128 17.3726 1.08276 16.0828 1.94264C15.6847 2.21334 13.6783 4.10824 11.6242
                        6.14646C7.67516 10.0637 7.51593 10.1751 6.71975 9.6178C6.48089 9.45856 6.36943 9.21971 6.36943 8.86939C6.36943
                        8.43945 6.64013 8.08913 8.23249 6.49678C10.5255 4.20378 10.5255 4.09232 8.34395 4.0127C5.66879 3.91716 5.8121
                        3.85347 3.05733 6.5764C-0.0318452 9.6178 -0.175157 9.87257 0.477709 11.1305C0.891722 11.9426 1.46497 12.1496
                        4.29937 12.5L6.60828 12.7866L9.37898 15.5254L12.1338 18.2643L12.5 20.7961C12.6911 22.1815 12.9459 23.5191
                        13.0573 23.7579C13.4713 24.6337 14.6019 25.0318 15.4936 24.6019C15.7962 24.4586 17.1178 23.2643 18.4236
                        21.9267C20.2548 20.0637 20.828 19.3789 20.9076 18.9649C21.051 18.2961 21.051 15.5573 20.9236 15.2229C20.7325
                        14.7133 20.2866 15 18.4713 16.7993C16.8949 18.3439 16.5287 18.6305 16.1147 18.6305C15.7803 18.6305 15.5414 18.5191
                        15.3822 18.2802C14.8248 17.484 14.9363 17.3248 18.8535 13.3758C20.8917 11.3216 22.7866 9.31525 23.0573 8.91716C24.0446
                        7.42034 24.7293 5.31843 24.9204 3.15283C25.0159 2.08595 24.9841 1.83117 24.6975 1.21015C24.1561 0.111427 23.2803
                        -0.143351 21.0191 0.159197Z" fill="#F04C40" />
                        <path d="M4.69745 15.5892C2.64331 16.7675 1.33758 19.2516 1.22611 22.1815C1.19427 23.1688 1.22611 23.3121 1.57643
                      23.5828C1.8949 23.8535 2.11783 23.8854 2.92994 23.8057C6.19427 23.4873 8.53503 22.1338 9.57006 20C9.90446 19.3312
                      9.90446 19.2675 9.64968 18.8854C9.29936 18.328 8.67834 18.344 8.23248 18.9013C6.71975 20.7962 5.73248 21.4331 3.80573
                      21.7835L3.07325 21.9268L3.21656 21.1943C3.56688 19.2675 4.20382 18.2803 6.09873 16.7675C6.6242 16.3376 6.67198 15.7166
                      6.17834 15.3822C5.70064 15.0478 5.62102 15.0637 4.69745 15.5892Z" fill="#F04C40" />
                    </g>
                    <defs>
                        <clipPath id="clip0_195_13">
                            <rect width="25" height="25" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </svg>
        )
    }

    const IconEncrypted = () => {
        return (
            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_195_19)">
                    <path d="M1.02856 0.333775C0.739277 0.413244 0.449991 0.635761 0.353563 0.87417C0.20892 1.17616 0.160706 4.21192 0.160706
                    12.1589V23.0305L0.54642 23.4755L0.932134 23.9205L13.4196 23.9682L25.8911 24L26.3732 23.5391L26.8393 23.0781V13.5576C26.8393
                    8.32848 26.7911 3.89404 26.7428 3.68742C26.5178 2.90861 26.1803 2.86093 19.9125 2.86093H14.0786L12.6803 1.50993L11.2982
                    0.158939L6.41249 0.174833C3.72856 0.174833 1.30178 0.254303 1.02856 0.333775ZM15.1875 8.48742C16.7303 9.20265 17.5018
                    10.4742 17.5178 12.2702V13.351H18.2411H18.9643V16.053V18.755L13.5482 18.7232L8.11606 18.6755V16.053V13.4305L8.80713
                    13.3828L9.48213 13.3351V12.3497C9.48213 10.9987 9.69106 10.347 10.3982 9.53642C11.6036 8.15364 13.5643 7.7245 15.1875
                    8.48742Z" fill="#F04C40" />
                    <path d="M12.616 11.2053C12.2785 11.5231 12.2143 11.7457 12.2143 12.4768V13.351H13.5H14.7857V12.4768C14.7857 11.4119
                    14.3196 10.8079 13.5 10.8079C13.1946 10.8079 12.8571 10.951 12.616 11.2053Z" fill="#F04C40" />
                </g>
                <defs>
                    <clipPath id="clip0_195_19">
                        <rect width="27" height="24" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        )
    }

    const whyUsData = [
        {
            icon: <IconTogether />,
            reason: 'Juntos para lhe entregar a melhor solução para o seu negócio.'
        },
        {
            icon: <IconRocket />,
            reason: 'Aceleramos o seu crescimento com soluções inteligentes e inovadoras.'
        },
        {
            icon: <IconEncrypted />,
            reason: 'Os seus dados sensíveis tratados com alto nível de segurança.'
        },
    ]

    return (
        <section id="why-us">
            <div className="overlay">
                <div id="data-overlay"></div>
            </div>

            <div className="content">
                <h1>
                    <span>Por que somos a</span>
                    <span>sua casa da Tecnologia?</span>
                </h1>

                <div className="cards">
                    {whyUsData.map((whyUs, index) => (
                        <div className="card" key={index}>
                            <div className="icon">{whyUs.icon}</div>
                            <h4>{whyUs.reason}</h4>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default WhyUs;