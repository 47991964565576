// Import React
import React from 'react';

// Import CSS
import '../styles/MainSlider.css'

// Import Components
import TypingEffect from './TypingEffect';

function MainSlider() {
    return (
        <section id="main-slider" className="content-slider">
            <section className="mid-section">
                <div className="slider-text text-slider">
                    <h1>Inovação digital com um toque humano</h1>
                    <h2>
                        <span>Soluções digiais</span>
                        <span>feitas <span className="font-color"><TypingEffect text="sob medida" speed={100} /></span></span>
                        <span>para o seu <span className="font-color"><TypingEffect text="sucesso" speed={100} /></span>!</span>
                    </h2>
                </div>
            </section>
        </section>
    );
}

export default MainSlider;