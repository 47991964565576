// Import React
import React from 'react';

// Import CSS
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/OurCases.css'

// Imports Data
import { CasesData } from '../data/global';

// Import Swiper Components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swipe Modules
import { Pagination } from 'swiper/modules';

const handleRedirectTo = (url) => {
    window.open(url, "_blank");
};

function OurCases() {
    return (
        <section id="cases">
            <h2 className="title-section" style={{ textAlign: 'baseline' }}>
                <span className="small">Cases</span>
                <span>Ideias que se tornaram realidade</span>
            </h2>
            <div className="cases">
                <Swiper
                    loop={true}
                    slidesPerView={3}
                    spaceBetween={0}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    className="mySwiper"
                    breakpoints={{
                        1270: {
                            slidesPerView: 3
                        },
                        850: {
                            slidesPerView: 2
                        },
                        0: {
                            slidesPerView: 1
                        }
                    }}
                >
                    {CasesData.map((cases, index) => (
                        <SwiperSlide key={index}>
                            <div className="case">
                                <div className="image-hover">
                                    <button onClick={() => handleRedirectTo(cases.link)} >Acessar Case</button>
                                    <div className="texts">
                                        <h4>{cases.title}</h4>
                                        <h5>{cases.description}</h5>
                                    </div>
                                </div>
                                <div className="image" style={{ background: `url(${cases.background})` }}></div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default OurCases;