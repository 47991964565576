// Import React
import React from 'react';

// Import CSS
import '../styles/Footer.css'

function Footer() {
    return (
        <footer>
            <h6>© 2024 FFHouse - A sua casa da Tecnologia. Todos os direitos reservados.</h6>
        </footer>
    );
}

export default Footer;