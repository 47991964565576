// Import React
import React from 'react';

// Import CSS
import './App.css';
import './styles/Common.css'

// Import Components
import Menu from './components/Menu';
import MainSlider from './components/MainSlider';
import AboutUs from './components/AboutUs';
import WhyUs from './components/WhyUs';
import OurSolutions from './components/OurSolutions';
import OurCases from './components/OurCases';
import OurPartners from './components/OurPartners';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
    return (
        <div className="App">
            <Menu />
            <MainSlider />
            <AboutUs />
            <WhyUs />
            <OurSolutions />
            <OurCases />
            <OurPartners />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;