// Import React
import React, { useEffect } from 'react';

// Import CSS
import '../styles/OurSolutions.css'

// Import Data
import { OurSolutionsData } from "../data/global";

function OurSolutions() {
    const handleRedirectTo = (url) => {
        window.open(url, "_blank");
    };

    useEffect(() => {
        const handleHashChange = () => {
            window.history.replaceState(null, null, window.location.pathname);
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    const IconForItens = () => (
        <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.91804 1.76281L7.30328 3.43453L7.73771 3.88143C8.10656 4.25384 8.19673 4.3035 8.32787 4.23729C8.41804 4.18763 9.18033 3.44281 10.041 2.57384L11.5984 0.993151L11.1475 0.537979C10.9016 0.289703 10.6639 0.0828066 10.6148 0.0910826C10.5738 0.0910826 9.80328 0.844186 8.91804 1.76281Z" fill="#292929" />
            <path d="M11.1311 3.39312C9.57376 4.98209 8.27048 6.28967 8.2295 6.28967C8.18852 6.28967 7.41802 5.54484 6.51638 4.6345C5.61474 3.72415 4.86065 2.97933 4.83606 2.97933C4.81147 2.97933 4.59015 3.18622 4.34425 3.4345L3.89343 3.88967L6.06556 6.08278L8.2377 8.27588L11.5738 4.9076C13.418 3.04553 14.918 1.48967 14.918 1.44002C14.918 1.35726 14.0738 0.496567 13.9918 0.496567C13.9754 0.496567 12.6885 1.79588 11.1311 3.39312Z" fill="#292929" />
            <path d="M0.491805 3.4345L0.0409851 3.88967L2.21312 6.08278L4.38525 8.27588L4.81148 7.84553C5.04918 7.60553 5.2459 7.37381 5.2459 7.32415C5.2459 7.2414 1.06558 2.97933 0.983608 2.97933C0.959018 2.97933 0.737706 3.18622 0.491805 3.4345Z" fill="#292929" />
        </svg>
    )

    const Solution = ({ icon, title, message, item, buttonLink }) => (
        <div className="solution">
            <div className="content">
                <div>
                    <div className="svg-container">{icon}</div>
                    <h2>{title}</h2>
                    <p>{message}</p>
                    <ul>
                        {item.map((action, index) => (
                            <li key={index} className="action-item">
                                <IconForItens />
                                <span>{action}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                <button onClick={() => handleRedirectTo(buttonLink)}>
                    <span>Solicitar </span>
                    <span style={{ fontWeight: '500' }}>orçamento</span>
                </button>
            </div>
        </div>
    );

    return (
        <section id="our-solutions" className="mid-section">
            <h2 className="title-section" style={{ textAlign: 'right' }}>
                <span className="small">Nossas Soluções</span>
                <span>Entenda como podemos lhe ajudar</span>
                <span>a alavancar o seu negócio!</span>
            </h2>

            <div className="solutions">
                {OurSolutionsData.map((solution, index) => (
                    <Solution
                        key={index}
                        icon={solution.icon}
                        title={solution.title}
                        message={solution.description}
                        item={solution.actions}
                        buttonLink={solution.link}
                    />
                ))}
            </div>
        </section>
    );
}

export default OurSolutions;